import React from 'react';
import clsx from 'clsx';
import styles from './custom_messages.module.scss';
import ConfirmAppointment from './templates/ConfirmAppointment';
import ComponentLoader from '../UI/ComponentLoader';

const MessageBody = ({ metadata, message, isLoading, mode = 'view' }) => {
  const isLive = mode === 'live'; // mode options case be view, edit or live
  // TODO: This component will render different components based on tag, currently it only supports Confirm Appointment Message
  return (
    <ComponentLoader
      isLoading={isLoading}
      customClass="w-100"
      addMinHeight
      blurContentWhileLoading
    >
      <div
        className={clsx(
          isLive ? 'is-live' : 'is-preview',
          styles.previewBodyInner,
        )}
      >
        <div className={clsx(styles.previewContainer)}>
          <ConfirmAppointment
            isLive={isLive}
            metadata={metadata}
            message={message}
          />
        </div>
      </div>
    </ComponentLoader>
  );
};

export default MessageBody;
